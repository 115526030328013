import { ArticleCardType } from "@/src/types";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import PremiumIcon from "../accoutrements/PremiumIcon";
import ImageWithFallback from "./FallbackImage";
import Lozenge from "../accoutrements/lozenge";
import HumanReadableDate from "./HumanReadableData";

export interface ArticleCardProps {
  post: ArticleCardType;
}

const ArticleCard = ({ post }: ArticleCardProps) => {
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    const theIndex = post.categories?.edges?.findIndex(function (category) {
      return category.node.name === "Subscriber Only";
    });
    setIsPremium(theIndex !== -1);
    //eslint-disable-next-line
  }, [post?.categories?.edges?.length]);

  if (!post) {
    return <></>;
  }

  const theCategory = post?.categories?.edges[0];
  const theUrl = post?.featuredImage?.node?.mediaDetails?.sizes
    ? post?.featuredImage?.node?.mediaDetails?.sizes[0].sourceUrl
    : "";

  return (
    <Link href={`/blog${post.uri}`} className="basis-full flex ac2 h-full">
      <div
        className={
          "grid grid-rows-1 gap-4 md:gap-0 grid-cols-5 new-article-card bg-white pl-4 md:pl-0 shadow-2xl cursor-pointer overflow-hidden w-[100%]"
        }
      >
        <div
          className={
            "image-container relative pb-[20%] col-span-3 md:pl-0 md:pr-0 md:pt-0"
          }
        >
          <Image
            src={theUrl}
            className="grow-image md:rounded rounded-r md:rounded-b-none"
            fill
            alt={theUrl}
            style={{
              objectFit: "cover",
            }}
          />
        </div>
        <div
          className={
            "text-container pb-2 pt-2 md:p-2 md:h-[100%] md:col-span-3 flex flex-col gap-2"
          }
        >
          <div className="grow flex flex-col h-[100%] gap-1">
            <div className="flex w-full items-center">
              <Lozenge
                color="dark"
                text={
                  post?.articleOptions?.highlightText
                    ? post?.articleOptions?.highlightText
                    : theCategory?.node?.name
                }
              />
              {isPremium && (
                <>
                  &nbsp;
                  <PremiumIcon />
                </>
              )}
            </div>
            <h6
              className={
                "text-base md:text-lg leading-tight md:font-bold font-heading"
              }
            >
              {post.title}
            </h6>
            <p className="text-xs text-slate-500 mt-auto">
              {post?.date && <HumanReadableDate date={post?.date} />}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ArticleCard;
